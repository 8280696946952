import React from 'react';
import styled from 'styled-components';
import Gap from '../__Gap';
import WangImg from 'imgs/1d1d/pc/profile.wang.png';
import ZhangImg from 'imgs/1d1d/m/profile.zhang.png';
import DengImg from 'imgs/1d1d/m/profile.deng.png';
import BgImg from 'imgs/1d1d/m/founder.wang.bg.png';
const Wrapper = styled.section`
  /* display: flex; */
  > section {
    ul {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
      margin-bottom: 0.6rem;
      text-align: left;
      padding: 0 0.82rem;
      li {
        line-height: 1.6;
        color: #636363;
        font-size: 0.24rem;
      }
    }
    .profile {
      display: flex;
      background: url(${BgImg}) no-repeat;
      height: 7.26rem;
      overflow: hidden;
      width: 100%;
      background-size: cover;
      position: relative;
      > img {
        width: 4.62rem;
        position: absolute;
        top: 0.95rem;
        right: 0;
      }
      .intro {
        color: #2c2c2c;
        top: 2.1rem;
        left: 0.65rem;
        position: absolute;
        .title {
          font-size: 0.42rem;
          font-weight: 800;
          line-height: 2;
          color: #636363;
        }
        .subTitle {
          font-size: 0.25rem;
        }
      }
    }

    &.wang {
      background-size: cover;
      .profile > img {
        width: 4.62rem;
      }
    }
    &.zhang {
      .profile {
        .intro {
          right: 0.65rem;
          left: auto;
        }
        img {
          left: 0;
          right: auto;
        }
      }
    }
    &.deng {
      .profile {
        .intro {
          left: 1.28rem;
        }
      }
    }
  }
`;
export default function Founders() {
  return (
    <Wrapper>
      <Gap />
      <section className="wang">
        <div className="profile">
          <div className="intro">
            <h4 className="title">王红兵</h4>
            <h5 className="subTitle">壹点壹滴创始人、CEO</h5>
          </div>
          <img src={WangImg} alt="Wang Profile" />
        </div>
        <ul>
          <li>毕业于清华大学，中欧国际工商学院EMBA学员 </li>
          <li>曾任《中国教育报》记者</li>
          <li>成功研发“天才幼儿园园长办公系统”</li>
          <li>曾出版专著《从幼儿园到清华园》和《红缨的力量》</li>
          <li>2001年，创立北京红缨教育 </li>
          <li>2007年，受北京大学光华管理学院龙军生教授点拨</li>
          <li>推出“赢在中国”北京红缨幼儿园连锁项目 </li>
          <li>2015年，带领“红缨教育”成功登陆资本市场</li>
          <li>2018年再度出发，创立壹点壹滴 </li>
          <li>立志将壹点壹滴打造为一家伟大的幼教互联网公司</li>
        </ul>
      </section>
      <Gap />
      <section className="zhang">
        <div className="profile">
          <div className="intro">
            <h4 className="title">张缪兴</h4>
            <h5 className="subTitle">总裁、联合创始人</h5>
          </div>
          <img src={ZhangImg} alt="Zhang Profile" />
        </div>
        <ul>
          <li>北京大学硕士，原伊顿连锁幼儿园信息化总监</li>
          <li>8年国内顶尖幼儿园一线运营管理与信息化经验</li>
          <li>4年的幼教+互联网创业经验</li>
        </ul>
      </section>
      <Gap />
      <section className="deng">
        <div className="profile">
          <img src={DengImg} alt="Deng Profile" />
          <div className="intro">
            <h4 className="title">邓迎锋</h4>
            <h5 className="subTitle">COO、联合创始人</h5>
          </div>
        </div>
        <ul>
          <li>曾任北大学园、北师大老教协连锁幼儿园副总经理</li>
          <li>13年早教幼教创业/从业经验，资深幼儿园管理经验</li>
          <li>幼儿园市场营销实战专家</li>
        </ul>
      </section>
      <Gap />
    </Wrapper>
  );
}
