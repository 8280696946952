import React from 'react';
import Container from '../__Container';
import Banner from '../__Banner';
import Founders from './_Founders';
import Map from './_Map';
import BannerImg from 'imgs/1d1d/pc/banner.founders.png';
import styled from 'styled-components';

//暂时注释
// const data = [
//   '壹点壹滴是中国幼教互联网平台。',
//   '它以互联网技术为驱动，以幼儿园公众号为载体，搭建中国幼教互联网平台，全力赋能幼儿园在生源、师资、管理、教学、家园共育等实现全面升级。',
//   '截至目前，全国已有20000+幼儿园上线，有200万+家庭在壹点壹滴这个平台上深度与幼儿园及第三方供应商交流互动。'
// ];

const data = [
  '壹点壹滴是中国幼教互联网卓越企业之一。',
  '旗下荣誉产品“幼教云”，全称“壹点壹滴幼教云”，是壹点壹滴联合中国未来研究会教育分会2021年推出的新幼教云平台。',
  '该平台，以幼儿园公众号为载体，以SaaS为支撑，全力借助互联网技术，赋能中国幼儿园，尤其是民办幼儿园，在后疫情时代，无论是管理还是教学，都能确保优质及安全。该平台使命：让办园更轻松！',
  '截至目前，全国已有20000+幼儿园上线，有200万+家庭在壹点壹滴这个平台上深度与幼儿园进行家园共育。'
];
const Text = styled.p`
  font-size: 0.24rem;
  line-height: 0.48rem;
  color: #636363;
  text-indent: 0.48rem;
  padding: 0 0.34rem;
  margin-top: 0.1rem;
`;
const Wrapper = styled.div`
  margin-top: 0.44rem;
  margin-bottom: 0.54rem;
`;
export default function Service() {
  return (
    <Container>
      <Banner banner={BannerImg} h1="关于壹点壹滴" h2s={['about 1d1d']} />
      <Wrapper>
        {data.map((itm, idx) => (
          <Text key={idx}>{itm}</Text>
        ))}
      </Wrapper>
      <Founders />
      <Map />
    </Container>
  );
}
