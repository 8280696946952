import React from 'react';
import styled from 'styled-components';

import MapImg from 'imgs/1d1d/m/founders.map.png';
const Wrapper = styled.section`
  .map {
    height: 7rem;
    background: url(${MapImg});
    background-size: cover;
    > hgroup {
      margin-top: 0.63rem;
      text-align: center;
      h2 {
        color: #969696;
        font-size: 0.28rem;
      }
      h3 {
        font-family: 'ITCAvantGardeStd-XLt';
        margin-bottom: 0.25rem;
        color: #ff7c34;
        font-size: 0.38rem;
        font-weight: 800;
        text-transform: uppercase;
      }
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 6.4rem;
      margin: 1.12rem auto 0;
      .card {
        background: #fff;
        padding: 0.3rem;
        box-shadow: 0px 0px 18px 0px rgba(111, 111, 111, 0.2);
        border-radius: 0.2rem;
        line-height: 1.2;

        margin-bottom: 0.26rem;
        > p {
          color: #2c2c2c;
          font-size: 0.22rem;
        }
        &.left {
          width: 2.43rem;
        }
        &.right {
          width: 3.53rem;
        }
        .num {
          font-family: RockoUltraFLF;
          font-size: 0.46rem;
          &.green {
            color: #00b6c6;
          }
          &.pink {
            color: #ff7c34;
          }
          &.blue {
            color: #27abff;
          }
          &.red {
            color: #ff5b5b;
          }
        }
      }
    }
  }
`;
export default function Map() {
  return (
    <Wrapper>
      <div className="map">
        <hgroup>
          <h3>企业规模</h3>
          <h2>business scale</h2>
        </hgroup>
        <div className="cards" data-aos="zoom-in">
          <div className="card left">
            <p>覆盖</p>
            <p className="num green">500+</p>
            <p>区县</p>
          </div>
          <div className="card right">
            <p>成就</p>
            <p className="num pink">500,000+</p>
            <p>优质教师</p>
          </div>
          <div className="card left">
            <p>赋能</p>
            <p className="num blue">20,000+</p>
            <p>幼儿园</p>
          </div>
          <div className="card right">
            <p>影响</p>
            <p className="num red">2,000,000+</p>
            <p>孩子和家庭</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
